import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class SecondPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const intl = this.props.pageContext.intl;

    return (
      <Layout title={siteTitle}>
        <SEO lang={intl.language} title={intl.messages.portfolioSeoTitle} description={intl.messages.portfolioSeoDescription} />
        <div className="portfolio">
          <div className="container">
            <ul className="portfolio-list">
              {posts.map(({ node }, index) => {
                const title = node.frontmatter.title || node.fields.slug

                return (
                  <li className="portfolio-item" style={{animationDelay: `${index*.5}s`}} key={node.fields.slug}>
                    <div className="portfolio-item-image">
                      <Link to={node.fields.slug}><Img fluid={node.frontmatter.preview.childImageSharp.fluid} alt={title + intl.messages.portfolioPhotoAlt} /><span className="button button-unfill"><FormattedMessage id="portfolioButton" /></span></Link>
                    </div>
                    <h3 className="portfolio-item-title">
                      <Link to={node.fields.slug}>{title}</Link>
                    </h3>
                    <p className="portfolio-item-info">{node.frontmatter.location}{(node.frontmatter.size)?(', '+node.frontmatter.size):('')}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default injectIntl(SecondPage)

export const pageQuery = graphql`
  query Portfolio($language: String){
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___number], order: ASC }, filter: {fields: {language: {eq: $language}}} ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            size
            location
            preview {
              childImageSharp {
                fluid(quality:75, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`